import React from 'react';
import './brain.css';
import BrainHeader from './brain-header/BrainHeader';
import BrainIntroText from './brain-introtext/BrainIntroText';
import BrainYoutube from './brain-youtube/BrainYoutube';
import BrainInfo from './brain-info/BrainInfo';
import BrainInbetween from './brain-inbetween/BrainInbetween';
import BrainWebbPortal from './brain-webbportal/BrainWebbPortal';
import BrainInfoGrid from './brain-info-grid/BrainInfoGrid';

const Brain = () => {
    return (
        <div className='brain'>
            <BrainHeader />
            <BrainIntroText />
            <BrainYoutube />
            <BrainInfo />
            <BrainInbetween />
            <BrainWebbPortal />
            <BrainInfoGrid />
        </div>
    );
};

export default Brain;