import React from 'react';
import './about.css';
import AboutPresentation from './aboutpresentation/AboutPresentation';
import WhoWe from './whowe/WhoWe';
import EmployeesText from './employeestext/EmployeesText';

const About = () => {
    return (
        <div className='about'>
            <AboutPresentation />
            <EmployeesText />
            <WhoWe />
        </div>
    );
};

export default About;