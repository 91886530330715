import React from 'react';
import './productyoutube.css';

const YouTubeEmbed = ({ product }) => {
  const embedUrl = `https://www.youtube.com/embed/${product.videoId}`;

  return (
    <div className="youtube-embed-container">
        <div className='youtube-embed-wrapper'>
            <iframe
                src={embedUrl}
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" 
                allowfullscreen
            />
        </div>
        <div className="youtube-embed-description">
            <h2 className="youtube-embed-title">Hur fungerar {product.namn}?</h2>
            <p>Tidsbesparande och enkel hantering av avfall</p>
            <ol>
                {Object.values(product.användning_manual).map((instruction, index) => (
                    <li key={index}>{instruction}</li>
                ))}
            </ol>
        </div>
    </div>
  );
};

export default YouTubeEmbed;
