import React, { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import { Link } from 'react-router-dom';
import 'react-slideshow-image/dist/styles.css';
import slidesData from './slidesData';
import './header.css';

const Header = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const properties = {
    prevArrow: <div className="custom-arrow custom-prev">‹</div>,
    nextArrow: <div className="custom-arrow custom-next">›</div>,
    indicators: (i) => (
      <div
        className={`indicator ${currentSlide === i ? 'active' : ''}`}
        onClick={() => setCurrentSlide(i)}
      >
        •
      </div>
    ),
    onChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    duration: 10000,
    transitionDuration: 200,
  };

  return (
    <div className="slide-container">
      <Slide {...properties}>
        {slidesData.map((slide) => (
          <div className="each-slide-effect" key={slide.id}>
            <div className="each-slide-bg-image" style={{ backgroundImage: `url(${slide.imageUrl})` }}>
              <div className="home-slider-overlay">
                <div className='home-slider-text-span'>
                  <span>{slide.text}</span>
                  <Link  to={slide.linkTo} className='home-slider-cta-button'>{slide.cta_text}</Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default Header;