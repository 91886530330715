import React from 'react';
import './news.css';
import NewsHeader from './newsheader/NewsHeader';
import NewsDisplay from './newsdisplay/NewsDisplay';

const News = () => {
    return (
        <div className='news'>
            <NewsHeader />
            <NewsDisplay />
        </div>
    );
};

export default News;