import React from 'react';
import Header from './header/Header';
import ProductPresentation from './product-presentation/ProductPresentation';
import MyGoals from './mygoals/MyGoals';
import CallToAction from './calltoaction/CallToAction';
import NewsHome from './newshome/NewsHome';

const Home = () => {
  return (
    <div className='homepage'>
        <Header />
        <ProductPresentation />
        <MyGoals />
        <CallToAction />
        <NewsHome />
    </div>
  )
}

export default Home