import React from 'react';

import ProductDisplay from '../../../components/productdisplay/ProductDisplay';

const Ovrigt = () => {
    return (
        <div>
            <div className="header-for-baler-products">
                <img src={`${process.env.PUBLIC_URL}/headers/ovrigt-header.webp`} alt="Baler Header" className="header-image" />
            </div>
            <div className="header-text">
                <h2>Brett sortiment av specialiserade produkter</h2>
                <p>
                Oavsett om du behöver en unik maskin för att hantera specifika uppgifter eller en speciallösning för att förbättra effektiviteten, 
                har vi ett brett utbud av produkter som kan skräddarsys efter dina unika behov. Våra övriga produkter är perfekta för företag som 
                söker flexibla och pålitliga verktyg för att förbättra sin drift.
                </p>
                <h2>Kvalitet och hållbarhet du kan lita på</h2>
                <p>
                Med vår långa erfarenhet och breda kundbas, från små företag till stora industrier, kan du vara säker på att våra produkter 
                håller högsta standard för kvalitet och hållbarhet. Vi är stolta över att erbjuda lösningar som inte bara uppfyller dina krav 
                utan också överträffar dina förväntningar.
                </p>
                <p>
                Letar du efter en pålitlig och användarvänlig produkt som hjälper dig att optimera ditt arbete? Vi har vad du behöver. 
                Utforska vårt sortiment av specialprodukter och hitta den perfekta lösningen för ditt företag. Låt oss hjälpa dig att 
                hitta rätt utrustning för dina specifika krav och se till att du har allt du behöver för att lyckas.
                </p>
            </div>
            <ProductDisplay typ="Övrigt" />
        </div>
    );
};

export default Ovrigt;