import React from 'react';
import ProductDisplay from '../../../components/productdisplay/ProductDisplay';
import './balpressar.css';

const Balpressar = () => {
    return (
        <div className='balpressar-container'>
            <div className="header-for-baler-products">
                <img src={`${process.env.PUBLIC_URL}/headers/balers-header.webp`} alt="Baler Header" className="header-image" />
            </div>
            <div className="header-text">
                <h2>Varför välja en balpress?</h2>
                <p>
                    En kompakt och bekväm lösning för att maximera utrymme och effektivitet, samtidigt som kostnader och avfallshantering minimeras. En balpress är en kompakt maskin designad för att komprimera och bunta återvinningsbara material som kartong, papper eller plast. Genom att komprimera dessa material till prydliga balar sparar balpressar utrymme, förenklar lagring och transport samt underlättar återvinning. De är nödvändiga verktyg för företag som vill hantera avfall effektivt samtidigt som de maximerar kostnadsbesparingar och hållbarhet.
                </p>
                <h2>Våra balpressar är byggda för att hålla</h2>
                <p>
                    Vi har lång erfarenhet av balpressar och ett brett spektrum av kunder. Från små närbutiker till stora distributionscenter, våra balpressar är den perfekta lösningen för detaljhandeln, livsmedelsbutiker, lager och vårdinrättningar. Med ett brett sortiment av balpressar i flera olika storlekar och kapaciteter kan våra balpressar anpassas för att passa dina unika behov – oavsett om det är en liten balpress för krympplast eller en balpress i kvarnstorlek för kartong.
                </p>
                <p>
                    Våra plast- och kartongbalpressar är speciellt designade för effektiv komprimering av återvinningsbart avfall, inklusive OCC, mjuk plast, aluminiumburkar med resterande vätskor och påsar. Den höga komprimeringsgraden ger kompakta och täta balar som är enkla att flytta och lagra.
                </p>
                <p>
                    Letar du efter en pålitlig balpress som är användarvänlig och säker? Vi kan hjälpa dig att hitta rätt tung balpress för kartong och olika andra avfallsströmmar. Läs mer om vårt urval av vertikala balpressar nedan.
                </p>
            </div>
            <ProductDisplay typ="Balpressar" />
        </div>
    );
};

export default Balpressar;
