import React from 'react';
import './serviceheader.css';
import HeaderService from '../../../assets/service_header.webp';

const ServiceHeader = () => {
    return (
        <div className='service-header-container'>
            <img src={HeaderService} alt="Service Header" className="service-header-image" />
        </div>
    );
};

export default ServiceHeader;