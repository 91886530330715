import React, { useState } from 'react';
import { FiChevronRight, FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import products from '../../../data/products.js';
import './allproducts.css';

import volumeIcon from '../../../assets/ikoner/waste.svg';
import fillOpening from '../../../assets/ikoner/size.svg';
import balWeight from '../../../assets/ikoner/weight.svg';
import enhetInnehåll from '../../../assets/ikoner/m3innehåll.svg';

const AllProducts = () => {
    const [selectedCategories, setSelectedCategories] = useState(['Alla']);
    const [selectedVolume, setSelectedVolume] = useState(['Alla']);
    const [selectedWasteType, setSelectedWasteType] = useState(['Alla']);
    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState({
        search: true,
        machineType: false,
        wasteType: false,
        wasteVolume: false,
    });

    const [isFilterVisible, setIsFilterVisible] = useState(false); // New state for mobile filter visibility

    const handleCategoryChange = (category) => {
        setSelectedCategories((prev) => {
            if (category === 'Alla') {
                return prev.includes('Alla') ? [] : ['Alla'];
            } else {
                const newCategories = prev.includes(category)
                    ? prev.filter((c) => c !== category)
                    : [...prev.filter((c) => c !== 'Alla'), category];
                return newCategories.length === 0 ? ['Alla'] : newCategories;
            }
        });
    };

    const handleVolumeChange = (volume) => {
        setSelectedVolume((prev) => {
            if (volume === 'Alla') {
                return prev.includes('Alla') ? [] : ['Alla'];
            } else {
                const newVolume = prev.includes(volume)
                    ? prev.filter((v) => v !== volume)
                    : [...prev.filter((v) => v !== 'Alla'), volume];
                return newVolume.length === 0 ? ['Alla'] : newVolume;
            }
        });
    };

    const handleWasteTypeChange = (type) => {
        setSelectedWasteType((prev) => {
            if (type === 'Alla') {
                return prev.includes('Alla') ? [] : ['Alla'];
            } else {
                const newTypes = prev.includes(type)
                    ? prev.filter((t) => t !== type)
                    : [...prev.filter((t) => t !== 'Alla'), type];
                return newTypes.length === 0 ? ['Alla'] : newTypes;
            }
        });
    };

    const toggleSection = (section) => {
        setIsOpen((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    const filteredProducts = products.filter((product) => {
        const categoryMatch =
            selectedCategories.includes('Alla') || selectedCategories.some((cat) => product.kategori.includes(cat));
        const volumeMatch =
            selectedVolume.includes('Alla') || 
            selectedVolume.includes(product.volym) || 
            (selectedVolume.includes('Mellan') && product.volym === 'Mellan / Stor') ||
            (selectedVolume.includes('Stor') && product.volym === 'Mellan / Stor');
        const wasteTypeMatch =
            selectedWasteType.includes('Alla') || selectedWasteType.some((type) => product.avfallstyp.includes(type));
        const searchMatch = product.namn.toLowerCase().includes(searchTerm.toLowerCase());
        return categoryMatch && volumeMatch && wasteTypeMatch && searchMatch;
    });

    const getSelectedFiltersSummary = () => {
        let filters = [];

        if (!selectedCategories.includes('Alla')) {
            filters = filters.concat(selectedCategories);
        }

        if (!selectedVolume.includes('Alla')) {
            filters = filters.concat(selectedVolume);
        }

        if (!selectedWasteType.includes('Alla')) {
            filters = filters.concat(selectedWasteType);
        }

        if (filters.length > 5) {
            return filters.slice(0, 5).join(', ') + ' +' + (filters.length - 5) + ' fler filter';
        }

        return filters.length > 0 ? filters.join(', ') : 'Inga filter valda';
    };

    const toggleFilterVisibility = () => {
        setIsFilterVisible((prev) => !prev);
    };

    return (
        <div className="all-products">
            {/* Toggle button for mobile view */}
            <button className="show-filters-toggle-button" onClick={toggleFilterVisibility}>
                Sök och filtrera
            </button>

            <div className={`filters ${isFilterVisible ? 'visible' : ''}`}>
                <h3>Välj mellan våra produkter</h3>
                <hr />
                <p className="dimmed">{getSelectedFiltersSummary()}</p>

                <div className="filter-section">
                    <div className="filter-header" onClick={() => toggleSection('search')}>
                        <h4>Sök</h4>
                        <FiChevronRight className={`icon ${isOpen.search ? 'open' : ''}`} />
                    </div>
                    <div className={`collapsible ${isOpen.search ? 'expanded' : ''}`}>
                        <div className="search-bar">
                            <FiSearch className="search-icon" />
                            <input
                                type="text"
                                placeholder="Sök produkter..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                            />
                        </div>
                    </div>
                </div>
                <hr />

                <div className="filter-section">
                    <div className="filter-header" onClick={() => toggleSection('machineType')}>
                        <h4>Maskintyp</h4>
                        <FiChevronRight className={`icon ${isOpen.machineType ? 'open' : ''}`} />
                    </div>
                    <div className={`collapsible ${isOpen.machineType ? 'expanded' : ''}`}>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="alla-maskintyp"
                                onChange={() => handleCategoryChange('Alla')}
                                checked={selectedCategories.includes('Alla')}
                            />
                            <label htmlFor="alla-maskintyp">Alla</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="vertikala"
                                onChange={() => handleCategoryChange('Vertikala balpressar')}
                                checked={selectedCategories.includes('Vertikala balpressar')}
                            />
                            <label htmlFor="vertikala">Vertikala balpressar</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="annan-utrustning"
                                onChange={() => handleCategoryChange('Annan utrustning')}
                                checked={selectedCategories.includes('Annan utrustning')}
                            />
                            <label htmlFor="annan-utrustning">Annan utrustning</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="mobila-komprimatorer"
                                onChange={() => handleCategoryChange('Mobila komprimatorer')}
                                checked={selectedCategories.includes('Mobila komprimatorer')}
                            />
                            <label htmlFor="mobila-komprimatorer">Mobila komprimatorer</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="stationara-skruv-komprimatorer"
                                onChange={() => handleCategoryChange('Stationära skruvkomprimatorer')}
                                checked={selectedCategories.includes('Stationära skruvkomprimatorer')}
                            />
                            <label htmlFor="stationara-skruv-komprimatorer">Stationära skruvkomprimatorer</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="horisontella-balpressar"
                                onChange={() => handleCategoryChange('Horisontella balpressar')}
                                checked={selectedCategories.includes('Horisontella balpressar')}
                            />
                            <label htmlFor="horisontella-balpressar">Horisontella balpressar</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="stationara-komprimatorer"
                                onChange={() => handleCategoryChange('Stationära komprimatorer')}
                                checked={selectedCategories.includes('Stationära komprimatorer')}
                            />
                            <label htmlFor="stationara-komprimatorer">Stationära komprimatorer</label>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="filter-section">
                    <div className="filter-header" onClick={() => toggleSection('wasteType')}>
                        <h4>Avfallssort</h4>
                        <FiChevronRight className={`icon ${isOpen.wasteType ? 'open' : ''}`} />
                    </div>
                    <div className={`collapsible ${isOpen.wasteType ? 'expanded' : ''}`}>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="alla-avfallssort"
                                onChange={() => handleWasteTypeChange('Alla')}
                                checked={selectedWasteType.includes('Alla')}
                            />
                            <label htmlFor="alla-avfallssort">Alla</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="plastfolie"
                                onChange={() => handleWasteTypeChange('Plastfolie')}
                                checked={selectedWasteType.includes('Plastfolie')}
                            />
                            <label htmlFor="plastfolie">Plastfolie</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="wellpapp"
                                onChange={() => handleWasteTypeChange('Wellpapp')}
                                checked={selectedWasteType.includes('Wellpapp')}
                            />
                            <label htmlFor="wellpapp">Wellpapp</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="papper"
                                onChange={() => handleWasteTypeChange('Papper')}
                                checked={selectedWasteType.includes('Papper')}
                            />
                            <label htmlFor="papper">Papper</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="hardplast"
                                onChange={() => handleWasteTypeChange('Hårdplast')}
                                checked={selectedWasteType.includes('Hårdplast')}
                            />
                            <label htmlFor="hardplast">Hårdplast</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="pet-flaskor"
                                onChange={() => handleWasteTypeChange('PET flaskor')}
                                checked={selectedWasteType.includes('PET flaskor')}
                            />
                            <label htmlFor="pet-flaskor">PET flaskor</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="aluminium-burkar"
                                onChange={() => handleWasteTypeChange('Aluminium burkar')}
                                checked={selectedWasteType.includes('Aluminium burkar')}
                            />
                            <label htmlFor="aluminium-burkar">Aluminium burkar</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="eps-skumplast"
                                onChange={() => handleWasteTypeChange('EPS Skumplast')}
                                checked={selectedWasteType.includes('EPS Skumplast')}
                            />
                            <label htmlFor="eps-skumplast">EPS Skumplast</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="metall-burkar"
                                onChange={() => handleWasteTypeChange('Metall burkar')}
                                checked={selectedWasteType.includes('Metall burkar')}
                            />
                            <label htmlFor="metall-burkar">Metall burkar</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="textilier"
                                onChange={() => handleWasteTypeChange('Textilier')}
                                checked={selectedWasteType.includes('Textilier')}
                            />
                            <label htmlFor="textilier">Textilier</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="flytande"
                                onChange={() => handleWasteTypeChange('Flytande')}
                                checked={selectedWasteType.includes('Flytande')}
                            />
                            <label htmlFor="flytande">Flytande</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="blandat"
                                onChange={() => handleWasteTypeChange('Blandat')}
                                checked={selectedWasteType.includes('Blandat')}
                            />
                            <label htmlFor="blandat">Blandat</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="organiskt"
                                onChange={() => handleWasteTypeChange('Organiskt')}
                                checked={selectedWasteType.includes('Organiskt')}
                            />
                            <label htmlFor="organiskt">Organiskt</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="tralador"
                                onChange={() => handleWasteTypeChange('Trälådor')}
                                checked={selectedWasteType.includes('Trälådor')}
                            />
                            <label htmlFor="tralador">Trälådor</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="epp-xps-cellplast"
                                onChange={() => handleWasteTypeChange('EPP / XPS Cellplast')}
                                checked={selectedWasteType.includes('EPP / XPS Cellplast')}
                            />
                            <label htmlFor="epp-xps-cellplast">EPP / XPS Cellplast</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="pur-polyuretaner"
                                onChange={() => handleWasteTypeChange('PUR Polyuretaner')}
                                checked={selectedWasteType.includes('PUR Polyuretaner')}
                            />
                            <label htmlFor="pur-polyuretaner">PUR Polyuretaner</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="skumplast-epe"
                                onChange={() => handleWasteTypeChange('Skumplast / EPE')}
                                checked={selectedWasteType.includes('Skumplast / EPE')}
                            />
                            <label htmlFor="skumplast-epe">Skumplast / EPE</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="trummor"
                                onChange={() => handleWasteTypeChange('Trummor')}
                                checked={selectedWasteType.includes('Trummor')}
                            />
                            <label htmlFor="trummor">Trummor</label>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="filter-section">
                    <div className="filter-header" onClick={() => toggleSection('wasteVolume')}>
                        <h4>Avfallsmängd</h4>
                        <FiChevronRight className={`icon ${isOpen.wasteVolume ? 'open' : ''}`} />
                    </div>
                    <div className={`collapsible ${isOpen.wasteVolume ? 'expanded' : ''}`}>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="alla"
                                onChange={() => handleVolumeChange('Alla')}
                                checked={selectedVolume.includes('Alla')}
                            />
                            <label htmlFor="alla">Alla</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="liten"
                                onChange={() => handleVolumeChange('Liten')}
                                checked={selectedVolume.includes('Liten')}
                            />
                            <label htmlFor="liten">Liten</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="mellan"
                                onChange={() => handleVolumeChange('Mellan')}
                                checked={selectedVolume.includes('Mellan')}
                            />
                            <label htmlFor="mellan">Mellan</label>
                        </div>
                        <div className='check-boxes-div'>
                            <input
                                type="checkbox"
                                id="stor"
                                onChange={() => handleVolumeChange('Stor')}
                                checked={selectedVolume.includes('Stor')}
                            />
                            <label htmlFor="stor">Stor</label>
                        </div>
                    </div>
                </div>
                <hr />
            </div>

            <div className="all-products-product-list">
                {filteredProducts.map((product) => (
                    <Link to={`/produkter/${product.id}`} key={product.id} className="all-products-product-item">
                        <h2 className='flex-column-h2'>{product.namn}</h2>
                        <div className="all-products-image-thumbnail-container">
                            <img src={product.imageUrl} alt={product.namn} className="all-products-image-thumbnail" />
                        </div>
                        <div className="all-products-product-details">
                            <h2 className='standard-h2-display'>{product.namn}</h2>
                            <div className="all-products-specific-information">
                                {product.volym && (
                                    <div className="all-products-icon-and-text">
                                        <img src={volumeIcon} alt="Avfalls volym ikon" className="icon" />
                                        <h3>Avfalls volym</h3>
                                        <p>{product.volym}</p>
                                    </div>
                                )}
                                {product.fyllningsöppning && (
                                    <div className="all-products-icon-and-text">
                                        <img src={fillOpening} alt="Fyllningsöppning ikon" className="icon" />
                                        <h3>Fyllningsöppning</h3>
                                        <p>{product.fyllningsöppning}</p>
                                    </div>
                                )}
                                {product.innehåll && (
                                    <div className="all-products-icon-and-text">
                                        <img src={enhetInnehåll} alt="Enhet innehåll ikon" className="icon" />
                                        <h3>Enhet</h3>
                                        <p>Innehåll (m3) {product.innehåll}</p>
                                    </div>
                                )}
                                {product.balvikt &&
                                    (product.balvikt.cardboard ||
                                        product.balvikt.plastic ||
                                        product.balvikt.textilier ||
                                        product.balvikt.plastic_bottles ||
                                        product.balvikt.aluminium_cans) && (
                                        <div className="all-products-icon-and-text">
                                            <img src={balWeight} alt="Balvikt ikon" className="icon" />
                                            <h3>Balvikt</h3>
                                            <p>
                                                {product.balvikt.cardboard && (
                                                    <>
                                                        Wellpapp {product.balvikt.cardboard} kg
                                                        <br />
                                                    </>
                                                )}
                                                {product.balvikt.plastic && (
                                                    <>
                                                        Plastfolie {product.balvikt.plastic} kg
                                                        <br />
                                                    </>
                                                )}
                                                {product.balvikt.textilier && (
                                                    <>
                                                        Textilier {product.balvikt.textilier} kg
                                                        <br />
                                                    </>
                                                )}
                                                {product.balvikt.aluminium_cans && (
                                                    <>
                                                        Aluminium burkar {product.balvikt.aluminium_cans} kg
                                                        <br />
                                                    </>
                                                )}
                                                {product.balvikt.plastic_bottles && (
                                                    <>
                                                        PET flaskor {product.balvikt.plastic_bottles} kg
                                                        <br />
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    )}
                            </div>
                            <hr />
                            <p className="all-products-descriptive-product-text">{product.beskrivning}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default AllProducts;
