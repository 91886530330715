import React from 'react';
import './mygoals.css';
import { FaCheck } from 'react-icons/fa';

const MyGoals = () => {
    return (
        <div className='our-goals-container'>
            <h1>Vårt mål är...</h1>
            <h2>Att erbjuda marknadens bästa och mest konkurrenskraftiga lösningar inom avfallskomprimering. Hur gör vi det?</h2>
            <ul>
                <li><FaCheck className="checkmark" /> Fokus på lägsta totalkostnad över 10 år</li>
                <li><FaCheck className="checkmark" /> Genom fördjupad kunskap om våra kunder och deras behov</li>
                <li><FaCheck className="checkmark" /> Genom att om och om igen bevisa att vi är rätt leverantör</li>
            </ul>
            <p>Tillsammans med oss, skall våra kunder att ha en mer effektiv, lönsam och funktionell återvinning.</p>
        </div>
    );
}

export default MyGoals;