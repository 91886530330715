import React from 'react';
import './brainwebbportal.css';
import { RiDownload2Fill } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";

const BrainWebbPortal = () => {
    return (
        <div className='brain-webportal-container'>
            <h1>BRA-IN Webportal 3.0</h1>
            <p>
                När din utrustning är ansluten via ett BRA-IN-märkt produkt, kan du få åtkomst till värdefulla data, som hjälper dig att övervaka och optimera din avfallshantering. De flesta data kan lätt exporteras till Excel för ytterligare analys, men till mer avancerade möjligheter som dataanalys är du välkommen att kontakta oss.
            </p>
            <p>
                Med åtkomst till webbportalen får du möjligheten till följande data om din anslutna utrustning:
            </p>
            <ul>
                <li><IoIosCheckmarkCircle /> Placering av utrustning – kan ses på Google koordinater</li>
                <li><IoIosCheckmarkCircle /> Status på maskiner – till optimerad service, via enkel och fjärrbetjänad service</li>
                <li><IoIosCheckmarkCircle /> Aktuell fyllningsgrad – till optimering av rutter för avfallsinsamling</li>
                <li><IoIosCheckmarkCircle /> Åtkomst till produktionsdata – direkt på skärmen eller via basrapport</li>
            </ul>
            <a href="/PDF_BROCHURE_B3.pdf" download> <RiDownload2Fill /> Broschyr - BRA-IN Webbportalen</a>
            <img src="/bra-in/brain-webbportal.PNG" alt="BRA-IN Webportal" />
        </div>
    );
};

export default BrainWebbPortal;