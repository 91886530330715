import React from 'react';
import './serviceheadertext.css';

const ServiceHeaderText = () => {
    return (
        <div className='service-header-text-container'>
            <h2>Snabb & kompetent</h2>
            <p>
            Vårt service team jobbar för god funktion och driftsäkerhet. När ni köper er utrustning från oss så erbjuder vi er, installation, användarutbildning, och service paket. 
            <br/>
            Vår önskan är att er avfallshantering är så effektiv som möjligt. 
            </p>
        </div>
    );
};

export default ServiceHeaderText;