import React from 'react';
import './serviceourconcept.css';
import { FaRegClock, FaChartBar, FaTools } from 'react-icons/fa';

const ServiceOurConcept = () => {
    return (
        <div className="service-concept-section">
            <h2>Vårt service koncept</h2>
            <p className="intro-text">
                Vi rekommendera er att skriva ett service avtal med oss på er utrusning för att slippa stillastånd på era maskiner. Över tid kommer ni att spara pengar, och ert arbetssätt kommer att vara så effektivt som möjligt.
            </p>
            <div className="service-cards">
                <div className="service-card">
                    <FaRegClock className="service-icon" />
                    <h3>Samarbetsavtal</h3>
                    <h4>Standard avtal</h4>
                    <ul>
                        <li>Vi kommer när ni ringer.</li>
                        <li>Ingen prioriterad plats i eventuell jobb kö.</li>
                        <li>Ni betalar för överenskommna jobb. Inget mera.</li>
                    </ul>
                </div>
                <div className="service-card">
                    <FaChartBar className="service-icon" />
                    <h3>Service Mellan</h3>
                    <h4>Mellan avtalet inkl Safety check</h4>
                    <ul>
                        <li>Låga kostnader per besök.</li>
                        <li>Alltid prioriterade i eventuell kö.</li>
                        <li>Service.</li>
                        <li>CE kontroll av alla funktioner.</li>
                        <li>Vi försäkrar att maskinen är laglig och säker att använda.</li>
                        <li>Full översikt av alla funktioner för bästa möjliga användning och livslängd.</li>
                    </ul>
                </div>
                <div className="service-card">
                    <FaTools className="service-icon" />
                    <h3>All Inklusiv</h3>
                    <h4>Full service avtal</h4>
                    <ul>
                        <li>Vi försäkrar att er utrustning är laglig och i full drift hela tiden.</li>
                        <li>Alltid prioriterade i eventuell kö.</li>
                        <li>Inkl reservdelar.</li>
                        <li>Alla kostnader för service och reparationer är inkluderade. Inga extra räkningar eller överaskningar.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ServiceOurConcept;