import React, { useState } from 'react';
import './contact.css';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaCheckCircle } from 'react-icons/fa';
import { FaPhoneVolume } from "react-icons/fa6";
import headerImage from '../../assets/header_contact2.webp';
import { Link } from 'react-router-dom'; // Assuming you are using react-router for navigation

const Contact = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(data).toString()
        })
        .then(() => setIsSubmitted(true))
        .catch((error) => alert(error));
    };

    return (
        <div className="contact-container">
            <div className="contact-normal" style={{ backgroundImage: `url(${headerImage})` }}>
                <h2>Kontakt</h2>
                <p>Har du frågor eller funderingar? Tveka inte på att kontakta oss.</p>
            </div>
            <div className="contact-info">
                <div className="main-phonenumber">
                    <FaPhoneVolume />
                    <div className="main-phonenumber-text">
                        <span>Telefon</span>
                        <span className='phone-number-thin'>0645 10 057</span>
                    </div>
                </div>
                <div className="service-contact">
                    <div className="info-item">
                        <FaPhoneAlt /> <span>För service: 0645 10 077</span>
                    </div>
                    <div className="info-item">
                        <FaEnvelope /> <span>Vilhelm@cyclesolutions.se</span>
                    </div>
                    <div className="info-item">
                        <FaMapMarkerAlt /> <span>Magnarpsbyaväg 339, 266 55 Vejbystrand</span>
                    </div>
                </div>
            </div>
            <div className="contact-form-container">
                {isSubmitted ? (
                    <div className="confirmation-message">
                        <FaCheckCircle size={50} color="#4caf50" />
                        <h3>Tack för ditt meddelande!</h3>
                        <p>Vi återkommer till dig så snart som möjligt.</p>
                        <Link to="/" className="back-home-link">Tillbaka till startsidan</Link>
                    </div>
                ) : (
                    <form 
                        name="contact" 
                        method="post" 
                        onSubmit={handleSubmit}
                        className="contact-form"
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="form-group">
                            <label htmlFor="namn">Namn</label>
                            <input type="text" id="namn" name="namn" required className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="epost">Epost</label>
                            <input type="email" id="epost" name="epost" required className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="företag">Företag</label>
                            <input type="text" id="företag" name="företag" required className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="meddelande">Meddelande</label>
                            <textarea id="meddelande" name="meddelande" required className="form-control"></textarea>
                        </div>
                        <button type="submit" className="submit-button">Skicka meddelande</button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default Contact;
