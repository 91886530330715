import React, { useState } from 'react';
import './productspecifications.css';

const ProductSpecifications = ({ product }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const specifications = [
        { label: 'Balvikt - papp (kg)', value: product.balvikt?.cardboard },
        { label: 'Balvikt - plast (kg)', value: product.balvikt?.plastic },
        { label: 'Balvikt - Aliminium burkar (kg)', value: product.balvikt?.aluminium_cans },
        { label: 'Balvikt - PET-flaskor (kg)', value: product.balvikt?.plastic_bottles },
        { label: 'Balvikt - Textilier (kg)', value: product.balvikt?.textilier },
        { label: 'Balstorlek BxDxH (mm)', value: product.balstorlek },
        { label: 'Presstryck (t)', value: product.presstryck },
        { label: 'Strömförsörjning', value: product.strömförsörjning },
        { label: 'Motorstorlek (kW)', value: product.motorstorlek },
        { label: 'Ljudnivå (dB)', value: product.ljudnivå },
        { label: 'Arbetscykeltid (sek)', value: product.arbetscykeltid },
        { label: 'Mått BxDxH (mm)', value: product.mått },
        { label: 'Vikt (kg)', value: product.vikt },
        { label: 'Fyllningsöppning BxD (mm)', value: product.fyllningsöppning },
        { label: 'Fyllningshöjd (mm)', value: product.fyllningshöjd },
        { label: 'Presskammarens höjd (mm)', value: product.presskammarens_höjd },
        { label: 'Slaglängd (mm)', value: product.slaglängd },
        { label: 'Antal band', value: product.antal_band },
        { label: 'Bindings typ', value: product.bindningstyp },
        { label: 'Ljus vid full bal', value: product.ljus_vid_full_bal },
        { label: 'Utbaling', value: product.utbalning },
        { label: 'Lastkapacitet (kg)', value: product.lastkapacitet },
        { label: 'Avlastningshöjd (mm)', value: product.avlastningshöjd },
    ];

    const filteredSpecifications = specifications.filter(spec => spec.value !== "SAKNAS" && spec.value !== undefined);

    return (
        <div className="spec-container">
            <h2>Tekniska specifikationer</h2>
            <div className="spec-flexbox">
                <div className="spec-content">
                    <div className={`spec-table ${expanded ? 'expanded' : ''}`}>
                        <table>
                            <tbody>
                                {filteredSpecifications.map((spec, index) => 
                                        <tr key={index}>
                                            <td>{spec.label}</td>
                                            <td>{spec.value}</td>
                                        </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="toggle-details" onClick={handleToggle}>
                            {expanded ? "Se mindre" : "Se mer"} <span>{expanded ? "−" : "+"}</span>
                    </div>
                </div>
                <div className="spec-image-and-brochure">
                    <img src={product.dimensionsImg} alt="Product Specifications" />
                    <a href={product.brochureUrl} className="download-brochure" target="_blank" rel="noopener noreferrer">Ladda ner broschyren</a>
                </div>
            </div>
        </div>
    );
};

export default ProductSpecifications;