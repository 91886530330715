import React from 'react';
import './calltoaction.css';
import { Link } from 'react-router-dom';

const CallToAction = () => {
    return (
        <div className='CTA-homepage'>
            <h1>Redo att prata om hur ni kan spara tid och pengar?</h1>
            <h2>Kontakta oss för en kostnadsfri konsultation.</h2>
            <h2>Vi är alltid redo till att guida dig till rätt val av komprimator och balpress.</h2>
            <Link to="/kontakt" className='CTA-button-homepage'>Kontakta oss</Link>
        </div>
    );
}

export default CallToAction;