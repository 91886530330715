import React from 'react';
import './service.css';
import ServiceHeader from './serviceheader/ServiceHeader';
import ServiceHeaderText from './serviceheadertext/ServiceHeaderText';
import ServiceQuadSection from './servicequadsection/ServiceQuadSection';
import ServiceOurConcept from './serviceourconcept/ServiceOurConcept';

const Service = () => {
    return (
        <div className='service'>
            <ServiceHeader />
            <ServiceHeaderText />
            <ServiceQuadSection />
            <ServiceOurConcept />
        </div>
    );
};

export default Service;