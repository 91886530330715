import React from 'react';
import './training.css';
import TrainingPageContents from './training-page-contents/Training-Page-Contents';

const Training = () => {
    return (
        <div className='training'>
            <TrainingPageContents />
        </div>
    );
};

export default Training;