import React from 'react';
import './servicequadsection.css';
import { FcCheckmark } from "react-icons/fc";

import servicepic1 from '../../../assets/service/service_picture1.jpg';
import servicepic2 from'../../../assets/service/service_picture2.jpg';

const ServiceQuadSection = () => {
    return (
        <div className="service-quad-section">
            <div className="quad-item quad-text">
                <h2>Håll din utrustning “up to date”</h2>
                <p>Försäkra dig om att er utrustning är välskött. Detta leder till en låg totalkostnad av ägandeskap och en säker användning.</p>
                <p>Vårt service och underhållsprogram kan bli anpassad till alla era krav och önskemål. Allt från vanlig underhållsservice till jour utryckningar. Vi håller er maskinflotta igång.</p>
            </div>
            <div className="quad-item quad-image quad-image-1" style={{ backgroundImage: `url(${servicepic1})` }}></div>
            <div className="quad-item quad-image" style={{ backgroundImage: `url(${servicepic2})` }}></div>
            <div className="quad-item quad-text">
                <h2>Varför använda våra service tekniker?</h2>
                <ul>
                    <li><FcCheckmark /> Väl utbildade</li>
                    <li><FcCheckmark /> Hög tillgänglighet</li>
                    <li><FcCheckmark /> Uppkopplings möjlighet till maskinerna</li>
                    <li><FcCheckmark /> Klarar av andra märken än våra egna</li>
                </ul>
            </div>
        </div>
    );
};

export default ServiceQuadSection;