import React from 'react';
import './newsdisplay.css';

import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import newsArticles from '../../../components/news/newsarticles';

const NewsDisplay = () => {
    return (
        <div className="news-container">
            {newsArticles.map((article, index) => (
                <div key={index} className="news-card">
                    <img src={article.image} alt={article.title} />
                    <div className="news-card-content">
                        <h2 className="news-card-title">{article.title}</h2>
                        <p className="news-card-date">{article.date}</p>
                        <p className="news-card-description">{article.description}</p>
                        <Link to={article.link} className='news-card-read-more'>Läs mer <FaArrowRight /></Link>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default NewsDisplay;
