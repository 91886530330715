import image1 from '../../assets/nyheter/artikel1.png';
import image2 from '../../assets/nyheter/artikel2.png';
import image3 from '../../assets/nyheter/artikel3.png';
import image4 from '../../assets/nyheter/artikel4.png';

const newsArticles = [
    {
        title: "Avfall ifrån produktionen - och hur man hanterar det",
        date: "2023.03.09",
        description: "Hur man hanterar produktionsavfall? Tack vare balpressar och komprimatorer kan avfallshanteringen bli mer effektiv!",
        link: "/news/1",
        image: image1
    },
    {
        title: "Balpressar och pressar för plast: vad bör man veta?",
        date: "2022.04.20",
        description: "Läs mer om vilket plastavfall du kan komprimera i Bramidan balpressar och pressar.",
        link: "/news/2",
        image: image2
    },
    {
        title: "Värmeteamet fick en grönare profil med sortering och komprimering av sitt avfall",
        date: "2022.04.05",
        description: "Extra avfallshantering i samband med returförpackningar från kunder gav ett nytt synsätt på sortering och återvinning.",
        link: "/news/3",
        image: image3
    },
    {
        title: "Horisontell och vertikal balpress - vilken ska man välja? Upptäck fördelarna med båda lösningarna",
        date: "2022.01.25",
        description: "Många företag kämpar med problemet med avfallshantering. Lösningar som möter företagens behov är balpressar: horisontella och vertikala.",
        link: "/news/4",
        image: image4
    },
    {
        title: "Horisontell och vertikal balpress - vilken ska man välja? Upptäck fördelarna med båda lösningarna",
        date: "2022.01.25",
        description: "Många företag kämpar med problemet med avfallshantering. Lösningar som möter företagens behov är balpressar: horisontella och vertikala.",
        link: "/news/4",
        image: image4
    }
];

export default newsArticles;