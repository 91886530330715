import React from 'react';
import './productloading.css';

const ProductLoading = () => {
    return (
        <div className='product-loading'>
            <h1>Loading...</h1>
        </div>
    );
};

export default ProductLoading;