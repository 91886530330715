import React from 'react';
import './braininfo.css';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiDownload2Fill } from "react-icons/ri";


const BrainInfo = () => {
    return (
        <div className='brain-info-container'>
            <h1>Hur kommer du igång med BRA-IN?</h1>
            <div className="brain-info-row">
                <p>
                    Vår BRA-IN-lösning kräver en anslutning via modem och ett aktivt abonnemang för att få åtkomst till din balpress 
                    eller komprimator och samla in data. Detta kan integreras i din utrustning, köpas separat eller levereras som en del av ditt servicepaket.
                </p>
                <p>
                    Aviseringar och automatiserade tjänster kan aktiveras av det lokala serviceteamet, och data visualiseras i BRA-IN-webbportalen.
                </p>
            </div>
            <h1>Vilka maskiner kan anslutas och hur?</h1> 
            <h4 className='brain-centered-text-bigger'>
                Utnyttja vårt fullständiga BRA-IN-ekosystem, oavsett om du köper nya balpressar och komprimatorer från oss, 
                eller om du vill uppdatera din gamla utrustning. När du är ansluten ger ett aktivt abonnemang dig tillgång 
                till BRA-IN-webbportalen med värdefulla data för din avfallshantering.
            </h4>
            <div className="brain-info-components">
                <div className="brain-info-component">
                    <img src="/bra-in/brain-baler.jpeg" alt="BRA-IN Modem" />
                    <h2>BRA-IN Modem - till nya och gamla balpressar</h2>
                    <p>
                        Du kan beställa ett modem installerat från fabriken när du köper en ny balpress, eller få det installerat efter leverans på maskiner från 2011 och nyare.
                    </p>
                    <ul>
                        <li><IoIosCheckmarkCircle /> Lägre servicekostnader p.g.a. fjärrdiagnos till maskinen</li>
                        <li><IoIosCheckmarkCircle /> Automatiska avhämtningsbaserat på färdigställda balar</li>
                        <li><IoIosCheckmarkCircle /> Underrättelse om maskinfel via E-mail</li>
                        <li><IoIosCheckmarkCircle /> Nivåer av notifikationer till utvalda personer möjlig</li>
                        <li><IoIosCheckmarkCircle /> Möjlighet för automatisk återbeställning av förbrukningsvaror</li>
                        <li><IoIosCheckmarkCircle /> Online ändringar av maskinens inställningar (av servicetekniker)</li>
                    </ul>
                    <a href="/brochures/PDF_BROCHURE_B3.pdf" download> <RiDownload2Fill /> Broschyr - BRA-IN Modem</a>
                </div>
                <div className="brain-info-component">
                    <img src="/bra-in/brain-compressor.jpeg" alt="BRA-IN Control Unit" />
                    <h2>BRA-IN Control Unit - standard i nya mobilkomprimatorer</h2>
                    <p>
                        Alla nya mobilkomprimatorer levereras som standard med BRA-IN Control Unit. Det är en styr- och kommunikationsenhet som ger åtkomst till värdefulla data om dina maskiner.
                    </p>
                    <ul>
                        <li><IoIosCheckmarkCircle /> Automatisk avhämtning beroende på fyllningsgrad</li>
                        <li><IoIosCheckmarkCircle /> Lägre servicekostnader p.g.a. fjärrsupport</li>
                        <li><IoIosCheckmarkCircle /> Bättre komprimering via optimerat pressprogram</li>
                        <li><IoIosCheckmarkCircle /> Antiblockeringsprogram minimerar felmeddelningar</li>
                        <li><IoIosCheckmarkCircle /> Energisparande funktion vid låg fyllningsgrad</li>
                        <li><IoIosCheckmarkCircle /> Uppsamlings av fullmeddelande och annan relevant driftsdata</li>
                    </ul>
                    <a href="/brochures/PDF_BROCHURE_B3.pdf" download> <RiDownload2Fill /> Broschyr - BRA-IN Control Unit</a>
                </div>
                <div className="brain-info-component">
                    <img src="/bra-in/brain-stranger.jpeg" alt="BRA-IN Link" />
                    <h2>BRA-IN Link - för gammal utrustning och främmande maskiner</h2>
                    <p>
                        BRA-IN Link tillåter signaler från utrustning med gamla styrenheter, såsom komprimatorer, skruvkomprimatorer och horisontella balpressar. Det är också möjligt att installera BRA-IN Link på andra maskiner. Funktioner kan variera beroende på utrustningens ålder och modell.
                    </p>
                    <ul>
                        <li><IoIosCheckmarkCircle /> Automatisk avhämtning baserat på fyllningsnivåer, spar transportkostnader</li>
                        <li><IoIosCheckmarkCircle /> GPS-lokalisering med batteribackup</li>
                        <li><IoIosCheckmarkCircle /> Lägre servicekostnader p.g.a. fjärråtkomst</li>
                        <li><IoIosCheckmarkCircle /> Online ändringar av maskinens inställningar (endast via servicetekniker)</li>
                        <li><IoIosCheckmarkCircle /> Underrättelse om maskinfel via E-mail</li>
                        <li><IoIosCheckmarkCircle /> Möjlighet för automatisk återbeställning av förbrukningsvaror</li>
                    </ul>
                    <a href="/brochures/PDF_BROCHURE_B3.pdf" download> <RiDownload2Fill /> Broschyr - BRA-IN Link</a>
                </div>
            </div>
        </div>
    );
};

export default BrainInfo;