import React from 'react';
import './aboutpresentation.css';
import bramidan_presto from '../../../assets/logotyper/bramidan_presto.svg';

const AboutPresentation = () => {
    return (
        <div className='about-presentation'>
            <h1>Om oss</h1>
            <p>
                Cycle Solutions erbjuder ett brett utbud av avfallskomprimerande maskiner av högsta kvalitet, 
                inklusive olika modeller och kapaciteter för att möta olika kundernas behov. 
                Vi säljer balpressar ifrån Bramidan tillverkade i Danmark och komprimatorer tillverkade av Presto i Tyskland.
            </p>
            <p>
                Sedan januari 2024 har vi på Cycle Solutions Sverige AB tagit över verksamhet ifrån Bramidan & Presto AB i Sverige.</p>

            <p>
                I över 50 år har både Bramidan och Presto utvecklat och producerat maskiner för komprimering av wellpapp, 
                plast och andra återvinningsbara material. På Cycle Solutions vill våra hängivna säljare alltid göra sitt 
                yttersta för att hitta den bästa lösningen på en balpress eller komprimator lösning till våra kunder.
            </p>
            <img src={bramidan_presto} alt="Bramidan Presto logo" className="bramidan-presto-logo" />
        </div>
    );
};

export default AboutPresentation;