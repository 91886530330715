import React from 'react';
import './braininfogrid.css';

const BrainInfoGrid = () => {
    return (
        <div className="brain-info-grid-container">
            <h1>360 Grad Översiktsdashboard</h1>
            <p>Du kan optimera effektiviteten hos dina maskiner genom att få en komplett översikt över all driftdata och prestandaindikatorer i realtid.</p>
            <div className="brain-info-grid">
                <div className="brain-info-item">
                    <img src="/bra-in/brain-grid-location.jpg" alt="Placering av utrustning" />
                    <h2>Placering av utrustning</h2>
                    <p>Kartvy över din utrustning baserat på angivna koordinater eller faktisk GPS-plats. Komprimatorer behöver antingen en BRA-IN Control Unit eller BRA-IN Link för att visa sin plats.</p>
                </div>
                <div className="brain-info-item">
                    <img src="/bra-in/brain-grid-livedata.jpg" alt="Live-data" />
                    <h2>Live-data</h2>
                    <p>Live-data från din maskin. Du kan jämföra datahistorik och se aktuella fyllningsnivåer för att få en översikt över din avfallshantering.</p>
                </div>
                <div className="brain-info-item">
                    <img src="/bra-in/brain-grid-app.jpg" alt="BRA-IN App" />
                    <h2>BRA-IN App</h2>
                    <p>Få relevant information och åtkomst till tjänster från din telefon via vår BRA-IN App. När dina maskiner är registrerade kommer du att kunna se manualer, instruktionsvideor, utdata och beställa upphämtning.</p>
                </div>
                <div className="brain-info-item">
                    <img src="/bra-in/brain-grid-pickup.jpg" alt="Upphämtning" />
                    <h2>Upphämtning</h2>
                    <p>Beställ upphämtning på ett specifikt datum eller "så snart som möjligt" baserat på fyllningsnivå eller balar på plats. Övervakning av utrustningens fyllningsnivåer är avgörande för effektivitet.</p>
                </div>
                <div className="brain-info-item">
                    <img src="/bra-in/brain-grid-status.png" alt="Maskinstatus" />
                    <h2>Maskinstatus</h2>
                    <p>Se olika informationskolumner om din utrustning, inklusive: fyllningsnivåer, maskinfel, adresser, serviceavtal och unika ID-nummer.</p>
                </div>
                <div className="brain-info-item">
                    <img src="/bra-in/brain-grid-chart.png" alt="Data- och diagramvy" />
                    <h2>Data- och diagramvy</h2>
                    <p>Få insikter i utrustningens användning och jämför med kostnader från avfallshanterare. Anpassa vyer baserat på tid, utrustning och önskad data för enkel export till Excel.</p>
                </div>
                <div className="brain-info-item">
                    <img src="/bra-in/brain-grid-data.jpg" alt="Listvy" />
                    <h2>Listvy</h2>
                    <p>Listvy av din utrustning med olika datarader för kund- och produktionsdata.</p>
                </div>
                <div className="brain-info-item">
                    <img src="/bra-in/brain-grid-fill.png" alt="Realtid & fyllningsnivåer" />
                    <h2>Realtid & fyllningsnivåer</h2>
                    <p>Spåra historiska data för att förstå användningsmönster och validera upphämtningar av avfallshanterare.</p>
                </div>
            </div>
        </div>
    );
}

export default BrainInfoGrid;