import React from 'react';
import './braininbetween.css';
import brain_inbetween_photo from '../../../../assets/BRA-in_inbetween.webp';

const BrainInbetween = () => {
    return (
        <div className='brain_inbetween_container'>
            <img src={brain_inbetween_photo} alt="bra-in bramidan intelligence" className="brain_inbetween_image" />
        </div>
    );
};

export default BrainInbetween;