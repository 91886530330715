import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import productsData from '../../data/products';
import './productdetails.css';
import ProductPictures from './productpictures/ProductPictures';
import ProductHighlights from './producthighlights/ProductHighlights';
import ProductSpecifications from './productspecifications/ProductSpecifications';
import YouTubeEmbed from './productyoutube/ProductYoutube';
import ProductLoading from './productloading/ProductLoading';
import ProductModels from './productmodels/ProductModels';


const ProductDetails = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const fetchedProduct = productsData.find(p => p.id === productId);
        setProduct(fetchedProduct);
    }, [productId]);

    if (!product) {
        return <ProductLoading />;
    }

    const hasYoutube = !!product.videoId;
    const isCompOrCC = product.typ === 'Komprimatorer' || product.id === 'CC';

    return (
        <div className="product___details">
            <ProductPictures product={product} />
            <ProductHighlights product={product} />
            {hasYoutube && <YouTubeEmbed product={product} />}
            {!isCompOrCC && <ProductSpecifications product={product} />}
            {product.modeller && <ProductModels product={product} />}
        </div>
    );
};

export default ProductDetails;