import React from 'react';
import { Link } from 'react-router-dom';
import './productpresentation.css';
import { FaArrowRight } from 'react-icons/fa';
import productCategories from './productCategories';

const ProductPresentation = () => {
    return (
        <div className="home-product-presentation">
            <div className="home-product-presentation-text">
                <h1>
                    Tysklands och Danmarks största komprimator & balpress tillverkare nu i egen regi i Sverige. Mer än 60 års av erfarenhet.
                </h1>
                <p>
                    Vårt företag levererar ett brett utbud av vertikala balpressar, horisontella balpressar och komprimatorer, 
                    noggrant utformade för att hantera och återvinna kartong, papper och andra avfallstyper.
                    <br></br>
                    <br></br> 
                    Vi levererar produkter från Bramidan Presto som är en framstående tillverkare med stark närvaro på den internationella marknaden. 
                    Cycle Solutions är med sitt huvudsäte i Sverige ensam distributör för Bramidan Presto i landet.  
                    <br></br>
                    <br></br>
                    Utforska vårt stora utbud av kompakta lösningar nedan.
                </p>
                <Link to="/products" className="product-link-home">
                    Se hela vårt produktsortiment <FaArrowRight />
                </Link>
            </div>
            <div className="home-product-grid">
                {productCategories.map((product, index) => (
                    <div className="home-product-card" key={index}>
                        <img src={product.image} alt={product.category} />
                        <h2>{product.category}</h2>
                        <p>{product.description}</p>
                        <Link 
                            to={`/produkter/${product.category === 'Övrigt' ? 'ovrigt' : product.category}`} 
                            className="home-product-link"
                        >
                            Läs mer
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductPresentation;