import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import logo from '../../assets/logotyper/logo_original.svg';
import { MdArrowDropDown, MdMenu, MdClose } from "react-icons/md";

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="navbar-container">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Company Logo" />
        </Link>
      </div>
      <nav className={`navbar ${menuVisible ? 'expanded' : ''}`}>
        <ul className="nav-menu">
          <li className="nav-item"><Link to="/">Hem</Link></li>
          <li className="nav-item"><Link to="/about">Om oss</Link></li>
          <li className="nav-item products-dropdown" ref={dropdownRef}>
            <Link to="" onClick={toggleDropdown}>
              Produkter <MdArrowDropDown className={`arrow-icon ${dropdownVisible ? 'up' : ''}`} />
            </Link>
            <ul className={`dropdown ${dropdownVisible ? 'show' : ''}`}>
              <li><Link to="/produkter">Samtliga produkter</Link></li>
              <li><Link to="/produkter/balpressar">Balpressar</Link></li>
              <li><Link to="/produkter/komprimatorer">Komprimatorer</Link></li>
              <li><Link to="/produkter/ovrigt">Övrigt</Link></li>
              <li><Link to="/produkter/brain">BRA-in datastyrning</Link></li>
            </ul>
          </li>
          <li className="nav-item"><Link to="/service">Service</Link></li>
          <li className="nav-item"><Link to="/nyheter">Nyheter & Referenser</Link></li>
        </ul>
        <Link to="/kontakt" className="cta-button">Kontakta oss</Link>
      </nav>
      <div className="menu-toggle" onClick={toggleMenu}>
        {menuVisible ? <MdClose /> : <MdMenu />}
      </div>
    </header>
  );
};

export default Navbar;