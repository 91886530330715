import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar, Footer } from './components';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/home/Home';
import AllProducts from './pages/products/allproducts/AllProducts';
import Balpressar from './pages/products/balpressar/Balpressar';
import Komprimatorer from './pages/products/komprimatorer/Komprimatorer';
import Ovrigt from './pages/products/ovrigt/Ovrigt';
import About from './pages/about/About';
import Service from './pages/service/Service';
import News from './pages/news/News';
import Contact from './pages/contact/Contact';
import ProductDetails from './components/productdetails/ProductDetails';
import Brain from './pages/products/brain/Brain';
import Training from './pages/training/Training';
import NotFound from './pages/notfound/NotFound';
import './App.css';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className='App'>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/produkter" element={<AllProducts />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/produkter/balpressar" element={<Balpressar />} />
          <Route path="/produkter/komprimatorer" element={<Komprimatorer />} />
          <Route path="/produkter/ovrigt" element={<Ovrigt />} />
          <Route path="/nyheter" element={<News />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/produkter/:productId" element={<ProductDetails />} />
          <Route path="/produkter/brain" element={<Brain />} />
          <Route path="/digital-utbildning-elgiganten" element={<Training />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App