import React from 'react';
import './productmodels.css';
import { RiDownload2Line } from "react-icons/ri";
import { FcCheckmark } from "react-icons/fc";

const ProductModels = ({ product }) => {
    const { modeller, modeller_bilder, brochureUrl} = product;

    return (
        <div className="product-models-container">
            <h1>Modeller i denna produktserie</h1>
            <div className="product-models-grid">
                {Object.keys(modeller).map((model, index) => (
                    <div className="product-model-item" key={model}>
                        <div className="model-picture-container">
                            <img src={modeller_bilder[index]} alt={model} />
                        </div>
                        <h2>{modeller[model][0]}</h2>
                        <p>{modeller[model][1]}</p>
                        <ul>
                            {modeller[model].slice(2).map((feature, featureIndex) => (
                                <li key={featureIndex}><FcCheckmark /> {feature}</li>
                            ))}
                        </ul>
                        <a href={brochureUrl[index]} download> <RiDownload2Line /> Broschyr</a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductModels;
