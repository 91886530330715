import React from 'react';
import './employeestext.css';

function EmployeesText() {
    return (
        <div className='employees-header-text'>
            <h1>Medarbetare på Cycle Solutions</h1>
            <p>
                Nedan hittar du våra lag av dedikerade anställda i olika avdelningar. Vi är redo att hjälpa och vägleda dig, bara kontakta oss.
            </p>
        </div>
    );
}

export default EmployeesText;