import React from 'react';
import './newsheader.css';
import headerImage from '../../../assets/nyheter/newsheader.webp';

const NewsHeader = () => {
    return (
        <div className="news-header">
            <div className='news-header-image'>
                <img src={headerImage} alt="cycle solutions news" className="header-image" />
            </div>
            <div className="news-header-content">
                <div className="text-container">
                    <div className='header-h1'>
                        <h1>Nyheter från </h1>
                        <h1 className='green-h1'>Cycle Solutions</h1>
                    </div>
                    <p>
                        Här hittar du de senaste uppdateringarna och nyheterna om våra balpressar och komprimatorer.
                        Vi strävar efter att ge dig den bästa lösningen för avfallshantering och återvinning.
                    </p>
                </div>
                <hr className="line-separator" />
            </div>
        </div>
    );
};

export default NewsHeader;
