import React from 'react';
import products from '../../data/products.js';
import './productdisplay.css';
import { Link } from 'react-router-dom';

import volumeIcon from '../../assets/ikoner/waste.svg';
import fillOpening from '../../assets/ikoner/size.svg';
import balWeight from '../../assets/ikoner/weight.svg';
import enhetInnehåll from '../../assets/ikoner/m3innehåll.svg';

const ProductDisplay = ({ typ }) => {
    const filteredProducts = products.filter(product => product.typ === typ);

    return (
        <div className='product-display-container'>
            <h1>{typ}</h1>
            <div className="product-list">
                {filteredProducts.map((product) => (
                    <Link to={`/produkter/${product.id}`} key={product.id} className="product-display-item">
                        <h2 className='h2-displayed-below-480'>{product.namn}</h2>
                        <div className="display-image-thumbnail-container">
                            <img src={product.imageUrl} alt={product.namn} className='display-image-thumbnail' />
                        </div>
                        <div className="product-details">
                            <h2 className='h2-hidden-below-480'>{product.namn}</h2>
                            <div className="specific-information">
                                {product.volym && (
                                    <div className='icon-and-text'>
                                        <img src={volumeIcon} alt="Avfalls volym ikon" className="icon" />
                                        <h3>Avfalls volym</h3>
                                        <p>{product.volym}</p>
                                    </div>
                                )}
                                {product.fyllningsöppning && (
                                    <div className='icon-and-text'>
                                        <img src={fillOpening} alt="Fyllningsöppning ikon" className="icon" />
                                        <h3>Fyllningsöppning</h3>
                                        <p>{product.fyllningsöppning}</p>
                                    </div>
                                )}
                                {product.balvikt && (
                                    <div className='icon-and-text'>
                                        <img src={balWeight} alt="Balvikt ikon" className="icon" />
                                        <h3>Balvikt</h3>
                                        <p>
                                            {product.balvikt.cardboard && <>Wellpapp {product.balvikt.cardboard} kg<br /></>}
                                            {product.balvikt.plastic && <>Plastfolie {product.balvikt.plastic} kg<br /></>}
                                            {product.balvikt.textilier && <>Textilier {product.balvikt.textilier} kg<br /></>}
                                            {product.balvikt.aluminium_cans && <>Aluminiumburkar {product.balvikt.aluminium_cans} kg<br /></>}
                                            {product.balvikt.plastic_bottles && <>Plastflaskor {product.balvikt.plastic_bottles} kg<br /></>}
                                            {product.balvikt.steel_drums && <>Trummor {product.balvikt.steel_drums} kg<br /></>}
                                        </p>
                                    </div>
                                )}
                                 {product.innehåll && (
                                    <div className='icon-and-text'>
                                        <img src={enhetInnehåll} alt="Enhet innehåll ikon" className="icon" />
                                        <h3>Enhet</h3>
                                        <p>Innehåll (m3) {product.innehåll}</p>
                                    </div>
                                )}
                            </div>
                            <hr className="separator" />
                            <p className='descriptive-product-text'>{product.beskrivning}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ProductDisplay;
