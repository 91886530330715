import React from 'react';
import './brainyoutube.css';

const BrainYoutube = () => {
    return (
        <div className='brain-youtube-container'>
            <div className="brain-youtube-embed-description">
                <p className='brain-small-letters'>BRAMIDAN INTELLIGENCE MONITORING SOLUTION</p>
                <h2 className="brain-youtube-embed-title">Var är BRA-IN?</h2>
                <p className='brain-youtube-description'>
                    BRA-IN, en förkortning för Bramidan Intelligence, är ett toppmodernt IoT-system, en samlingsbeteckning för 
                    den specialutvecklade teknik som integrerar all din komprimeringsutrustning i en sammanhängande IT-ram. 
                    <br /><br />
                    Detta system ger dig en omfattande översikt över din utrustning, vilket gör det möjligt för dig att minimera 
                    både direkta och indirekta kostnader för avfallshantering över tid.
                    <br /><br />
                    Oavsett om din uppsättning inkluderar några få balpressar och komprimatorer eller en stor mängd maskiner, 
                    levererar BRA-IN övervakningslösning betydande effektivitetsvinster. Det ger värdefulla insikter som kan 
                    hjälpa till att effektivisera dina operationer och minska utgifter relaterade till avfallshantering.
                </p>
            </div>
            <div className='youtube-embed-wrapper'>
                <iframe
                    src="https://www.youtube.com/embed/B-dbGHHhVqE?si=9mVjZJlB4vZG34D7"
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen
                />
            </div>
        </div>
    );
};

export default BrainYoutube;