import React from 'react';
import './newshome.css';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import newsArticles from '../../../components/news/newsarticles';

const NewsHome = () => {
    return (
        <div className='news-homepage'>
            <h2>Nyheter</h2>
            <div className='news-grid'>
                {newsArticles.map((article, index) => (
                    <div key={index} className='news-article'>
                        <img src={article.image} alt={article.title} />
                        <h3>{article.title}</h3>
                        <p className='date'>{article.date}</p>
                        <p>{article.description}</p>
                        <Link to={article.link} className='read-more'>Läs mer <FaArrowRight /></Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NewsHome;