import React from 'react';
import './producthighlights.css';
import '../../../data/products';
import { FiCheck } from 'react-icons/fi';

const ProductHighlights = ({ product }) => {
    return (
        <div className="highlights-container">
            <h2>Höjdpunkter och produktegenskaper</h2>
            <div className="highlights-content">
                <div className="left-highlights">
                    <div className="highlight">
                        <h3>{product.höjdpunkter.rubrik_ett}</h3>
                        <p>{product.höjdpunkter.text_ett}</p>
                    </div>
                    <div className="highlight">
                        <h3>{product.höjdpunkter.rubrik_två}</h3>
                        <p>{product.höjdpunkter.text_två}</p>
                    </div>
                </div>
                <div className="highlight highlight-list">
                    <h3>{product.höjdpunkter.rubrik_lista}</h3>
                    <ul>
                        {product.höjdpunkter.lista.map((item, index) => (
                            <li key={index}><FiCheck /> {item}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ProductHighlights;
