import React from 'react';
import './whowe.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa';

const WhoWe = () => {
    const employees = [
        {
            name: 'Vilhelm Svahn',
            role: 'Ägare',
            phoneNumber: '072 168 59 45',
            email: 'Vilhelm@cyclesolutions.se'
        },
        {
            name: 'Pär Svahn',
            role: 'Ägare',
            phoneNumber: '072 168 59 42',
            email: 'Par@cyclesolutions.se'
        },
        {
            name: 'Mikael Persson',
            role: 'Serviceansvarig',
            phoneNumber: ' 072 168 59 41',
            email: 'Service@cyclesolutions.se'
        },
        {
            name: 'Oskar Notelid',
            role: 'Ekonomiansvarig',
            phoneNumber: '072441666',
            email: 'oskar.notelid@vios.se'
        }
    ];

    return (
        <div className="whowe-container">
            {employees.map((employee, index) => (
                <div key={index} className="employee-card">
                    <h2>{employee.name}</h2>
                    <p>{employee.role}</p>
                    <p>
                        <FaPhone /> {employee.phoneNumber}
                    </p>
                    <p>
                        <FaEnvelope /> {employee.email}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default WhoWe;