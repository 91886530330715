import * as ppPictures from '../../../assets/home';

const productCategories = [
    {
        category: 'Balpressar',
        image: ppPictures.ppBaler,
        description: 'Kompakta och yteffektiva maskiner utformade för att pressa ihop kartong, plast och andra återvinningsbara material till täta, stapelbara balar. Perfekta för företag med begränsat utrymme som behöver effektiv avfallshantering.'
    },
    {
        category: 'Komprimatorer',
        image: ppPictures.ppCompactor,
        description: 'Mångsidiga maskiner som minskar volymen på avfallsmaterial genom att komprimera dem till mindre, mer hanterbara former. Anpassade för specifika avfallshanteringsbehov, med alternativ för olika storlekar och rörlighet.'
    },
    {
        category: 'Övrigt',
        image: ppPictures.ppÖvrigt,
        description: 'Upptäck vårt breda sortiment av ytterligare lösningar för avfallshantering och återvinning. Från specialanpassade maskiner till innovativa system, vi har allt du behöver för att effektivisera och förbättra din avfallshantering.'
    }
];

export default productCategories;