import React from 'react';

import ProductDisplay from '../../../components/productdisplay/ProductDisplay';

const Komprimatorer = () => {
    return (
        <div className='komprimatorer-container'>
            <div className="header-for-baler-products">
                <img src={`${process.env.PUBLIC_URL}/headers/compactors-header.webp`} alt="Compactors Header" className="header-image" />
            </div>
            <div className="header-text">
                <h2>Varför välja en komprimator?</h2>
                <p>
                    Komprimatorer erbjuder en effektiv lösning för avfallshantering genom att komprimera avfallet, 
                    maximera lagringsutrymmet och underlätta enklare avfallshantering, vilket i slutändan sparar tid och resurser. 
                    Genom att avsevärt minska avfallsvolymen optimerar komprimatorer lagringsutrymmet och effektiviserar avfallsinsamlingen. 
                    Denna effektivitetsökning kan leda till konkreta kostnadsbesparingar, eftersom färre insamlingsresor och minskade 
                    transportkostnader bidrar till en mer budgetvänlig strategi för avfallshantering.
                </p>
                <p>
                    Komprimatorer spelar också en viktig roll i att främja miljöansvar genom att minska behovet av deponi och minska 
                    koldioxidutsläppen som är förknippade med transport. Alla våra komprimatorer är anpassade för företag som hanterar stora mängder avfall.
                </p>
                <h2>Upptäck din perfekta komprimatorlösning</h2>
                <p>
                    Utforska vårt sortiment av komprimatorer designade för att möta de olika behoven hos lager, distributionscenter och industriföretag.
                </p>
                <p>
                    Oavsett om du letar efter en öppen auto-tie-komprimator eller en robust tvåkammar-modell för applikationer med hög volym, 
                    är vårt sortiment anpassat för att passa dina behov. Hitta din perfekta komprimator och börja optimera dina avfallshanteringsprocesser.
                </p>
            </div>
            <ProductDisplay typ="Komprimatorer" />
        </div>
    );
};

export default Komprimatorer;