import React from 'react';
import './brainintrotext.css';

const BrainIntroText = () => {
    return (
        <div className='brain-intro-text-container'>
            <h1>Anslut, Övervaka & Analysera Din Utrustning med BRA-IN</h1>
            <h3>Maximera potentialen hos din utrustning med vår intelligenta övervakningslösning, BRA-IN. Ta snabbare och bättre beslut samtidigt som du minskar driftkostnader och minimerar miljöpåverkan.</h3>
            <p>BRA-IN, vår maskinövervakningslösning, erbjuder många fördelar. Upplev realtidsåtkomst till data, driftstatusuppdateringar och sömlös maskinkoppling. Förenkla ditt arbetsflöde med fjärrsupport, automatiska aviseringar och detaljerade rapporter, allt tillgängligt via skrivbord eller app.</p>
            
            <h2>Så kommer du igång med BRA-IN</h2>
            <p>Vår BRA-IN-lösning kräver en anslutning via modem och en aktiv prenumeration för att samla in data och ansluta till din balpress eller komprimator. Detta kan integreras på utrustning som köps separat eller tillhandahålls som en del av ditt serviceprogram.</p>
            <p>Aviseringar och automatiska tjänster kan aktiveras av den lokala serviceteamet, och data visualiseras i BRA-IN-webbportalen.</p>
            
            <h2>Uppdatera gammal utrustning med ny teknik</h2>
            <p>BRA-IN Link tillåter signaler från utrustning med gamla styrsystem, såsom komprimatorer, balpressar, skruvkomprimatorer och horisontella balpressar. Det kan också installeras på olika utländska maskiner, inklusive balpressar, med funktionalitet som varierar beroende på ålder och modell.</p>
            <p>Med en BRA-IN Link får du tillgång till data från äldre utrustning, fjärrservice och lokalisering av maskiner via GPS. Detta innebär betydande besparingar på service och transporter – alternativ som normalt inte är tillgängliga för äldre utrustning.</p>
            
            <h2>Vilka maskiner kan anslutas och ur?</h2>
            <p>Oavsett om du köper nya balpressar och komprimatorer från oss eller vill uppdatera din befintliga utrustning, har BRA-IN dig täckt. När de är anslutna och med en aktiv prenumeration har du tillgång till BRA-IN-webbportalen, vilket ger värdefulla data för dina avfallshanteringsoperationer.</p>
        </div>
    );
};

export default BrainIntroText;