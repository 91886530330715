import headerImage1 from '../../../assets/home/FirstHeader.webp';
import headerImage2 from '../../../assets/home/SecondHeader.webp';
import headerImage3 from '../../../assets/home/ThirdHeader.webp';
const slides = [
    {
        id: 1,
        text: 'Cycle Solutions - Vi erbjuder balpressar för alla behov och avfallstyper',
        imageUrl: headerImage1,
        cta_text: 'Se våra produkter',
        linkTo: '/produkter/balpressar',
    },
    {
        id: 2,
        text: 'Använd våra komprimatorer för smidig hantering av ditt avfall',
        imageUrl: headerImage2,
        cta_text: 'Se våra produkter',
        linkTo: '/produkter/komprimatorer',
    },
    {
        id: 3,
        text: 'Kolla in våra serviceavtal för att hålla din maskin i toppskick',
        imageUrl: headerImage3,
        cta_text: 'Se våra serviceavtal',
        linkTo: '/service',
    },
];

export default slides;