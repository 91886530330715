import React from 'react';
import './brainheader.css';
import brain_header_photo from '../../../../assets/BRA-in_header.webp';

const BrainHeader = () => {
    return (
        <div className='brain_header_container'>
            <img src={brain_header_photo} alt="bra-in monitor system" className="brain_header_image" />
        </div>
    );
};

export default BrainHeader;